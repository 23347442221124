.footer {
  margin: 2rem 0.5rem 0;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
}

.footer a {
  color: #888;
  display: block;
}
