.container {
  max-width: 62.5rem;
  margin: 1.5rem auto;
  padding: 3vw;
}

.page {
  padding: 0.5rem;
}

.title {
  font-family: 'Montserrat', sans-serif;
}

.text-center {
  text-align: center;
}

.text-big {
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 5rem;
}

.text-medium {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.text-small {
  font-size: 0.5rem;
}


a {
  transition: color 0.2s ease-in-out;
  color: #222;
  text-decoration: none;
}

a:hover {
  color: #111;
}

.text-with-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 850px) {
  .text-with-image {
    grid-template-columns: 1fr;
  }
}

.text-with-image img {
  width: 100%;
  max-height: 35vh;
  object-fit: scale-down;
}

p {
  line-height: 1.5rem;
}
