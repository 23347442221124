.news-link {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4rem;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 850px) {
  .news-link {
    grid-template-columns: 1fr;
  }
}

.news-link img {
  max-height: 25vh;
  width: 100%;
  object-fit: scale-down;
}

.news-link-text {
  display: inline-grid;
  align-items: center;
  grid-row-gap: 1.5rem;
}

.news-link-blurb {
  font-size: 1.5rem;
  quotes: "\201E" "\201C";
  font-style: italic;
}

.news-link a:hover {
  text-decoration: underline;
}
