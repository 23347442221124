.header {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 2vh;
}

.header a {
  padding: 0.5rem;
  text-transform: uppercase;
  color: #888;
}

.header .active {
  color: #222;
}
