.project-preview {
  width: 100%;
}

.project-preview-image {
  height: 75vh;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-title {
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
  font-weight: 800;
  color: white;
  z-index: 999;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.7);
  opacity: 0;
}

.overlay, .project-title {
  transition: .5s ease;
  overflow:hidden;
}

.project-preview-image:hover .overlay, .project-preview-image:hover .project-title {
  opacity: 1;
}
