.project-image {
  max-height: 50vh;
  width: 100%;
  object-fit: cover;
  margin: 1.5rem 0;
}

.project-details {
  margin-bottom: 1.5rem;
}

.project-sections {
  display: grid;
  grid-gap: 1.5rem;
}

.project-section {
  display: inline-grid;
  grid-gap: 1.5rem;
  align-items: center;
}

.project-section-image {
  max-height: 50vh;
  object-fit: contain;
}

.project-section-image-big {
  max-height: 60vh;
  object-fit: cover;
}

.project-section * {
  width: 100%;
}
